import React, { Suspense, useContext, useState } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import "./scss/common.scss";
//import "react-select/dist/react-select.min.css";
import "./scss/reactselect.scss";
import "./scss/reactphone.scss";
import "./scss/reactpopover.scss";
import "./scss/reactdates.scss";
import { ApolloProvider } from "@apollo/react-hooks";
import LoadingSpinner from "./components/LoadingSpinner";
import { retry } from "./utils/RetryLoadable";
import DefaultLayout from "./containers/DefaultLayout";
// import Reservation from "./pages/reservation/Reservation";
// import routes from "./routes";
import { ThemeContext, lightTheme, darkTheme } from "./Theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./global";
import createApolloClient from "./apolloClient";

const Login = React.lazy(() => retry(() => import("./pages/login/Login")));
const GoogleLogin = React.lazy(() =>
  retry(() => import("./pages/login/GoogleLogin")),
);
const RegisterPeople = React.lazy(() =>
  retry(() => import("./pages/arranger/RegisterPeople")),
);

function App() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [endpoint, setEndpoint] = useState("/graphql");
  const [client, setClient] = useState(createApolloClient(endpoint));

  const changeEndpoint = (newEndpoint) => {
    setEndpoint(newEndpoint);
    setClient(createApolloClient(newEndpoint));
  };

  return (
    <ThemeProvider theme={theme !== "dark-theme" ? lightTheme : darkTheme}>
      <ApolloProvider client={client}>
        <GlobalStyles />
        <div className={`App ${theme}`}>
          <HashRouter>
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  element={<Login />}
                />
                <Route
                  exact
                  path="/google-cb"
                  name="Login"
                  element={
                    <GoogleLogin
                      endpoint={endpoint}
                      changeEndpoint={changeEndpoint}
                    />
                  }
                />
                <Route
                  exact
                  path="/register"
                  name="Register"
                  element={<RegisterPeople />}
                />

                <Route
                  path="*"
                  name="Home"
                  element={
                    <DefaultLayout theme={theme} toggleTheme={toggleTheme} />
                  }
                />
              </Routes>
            </Suspense>
          </HashRouter>
        </div>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
